@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.icon {
  cursor: pointer;
  transition: all 0.2s;
  color: #6941c6;

  @include singleFixedSize(22px);
  &-opened {
    transform: rotate(180deg);
  }
}

.chips {
  height: 38px;
  display: flex;
  align-items: center;
  border-radius: var(--cornerradius8);
  background: var(--cst-dropitem-background-default);
  padding: 0 5px;
  gap: 5px;

  cursor: pointer;
  &-opened {
    pointer-events: none;
  }
  &-disabled {
    pointer-events: none;
    opacity: 0.2;
  }
  &:hover {
    background: var(--cst-dropitem-background-hover);
  }
  &:active {
    background: var(--cst-dropitem-background-pressed);
  }
}

.label {
  @include typography-m;
  @include weight-medium;
}
