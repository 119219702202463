@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  position: relative;
}

.main_wrapper {
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 2px;
  height: 46px;
  width: 100%;
  max-width: 100%;
  border: 2px solid var(--cst-input-search-outline-default);
  border-radius: var(--cornerradius10);
  gap: 2px;
  margin-bottom: 0;
  transition: 0.2s all ease;

  @include min-1440-break {
    height: 50px;
    padding: 0 4px;
    border-radius: var(--cornerradius12);
  }

  &:hover {
    border: 2px solid var(--cst-input-search-outline-hover);
    box-shadow: 0 0 0 4px #6941c633;
  }
  &:focus-within {
    border: 2px solid var(--cst-input-search-outline-filled);
    box-shadow: 0 0 0 4px #6941c633;
  }
  //&.l {
  //  width: 969px;
  //  height: 50px;
  //}
  //&.m {
  //  width: 711px;
  //  height: 50px;
  //}
  //&.s {
  //  width: 504px;
  //  height: 50px;
  //}

  .input_wrapper {
    flex: 1;
    height: 95%;
    margin-left: 8px;
    @include min-1440-break {
      margin-left: 14px;
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }
  }

  .input_field {
    outline: none;
    border: none;
    height: 95%;
    width: 100%;
    font-family: Inter, sans-serif;
    color: var(--cst-input-search-text-typing);
    @include typography-s;
    @include weight-medium;

    @include min-1440-break {
      @include typography-base;
    }
    text-align: left;
  }

  .search_button {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--cornerradius8);
    background: var(--cst-buttons-primary-background-default);
    border: none;

    cursor: pointer;

    &:active {
      opacity: 0.8;
    }
  }
}

.drop_down {
  width: 100%;
  max-width: 100%;
  position: absolute;
  margin-top: 10px;
  padding: 10px 8px;
  border-radius: var(--cornerradius12);
  box-shadow: inset 0 0 0 2px var(--dropitem-area-outline-typing);
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 2px;

  @include min-1440-break {
    gap: 14px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
}

.item-icon {
  @include singleFixedSize(18px);

  @include min-1440-break {
    @include singleFixedSize(22px);
  }
}

.sections-wrapper,
.section,
.section-items {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin: 0;
  padding: 0;
  list-style: none;

  .section-title {
    color: var(--dropitem-area-title-pressed);
    margin-left: 12px;
    @include typography-s;
    @include weight-semi-bold;

    @include min-1440-break {
      @include typography-base;
    }
  }
}

.section-items {
  gap: 4px;

  .section-item {
    @include fixedSize(100%, 40px);

    border-radius: var(--cornerradius10);
    padding: 11px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    cursor: pointer;

    span[id='main-search-span'] {
      color: var(--cst-dropitem-text-default);

      @include typography-s;
      @include weight-medium;
    }

    &__recently {
      span[id='main-search-span'] {
        @include typography-base;
      }
    }

    &__focused {
      background: var(--cst-dropitem-background-hover);
      span[id='main-search-span'] {
        color: var(--cst-dropitem-text-hover);
      }
    }

    &__disabled {
    }

    @include min-1440-break {
      gap: 8px;
      @include fixedSize(100%, 44px);
      span[id='main-search-span'] {
        @include typography-base;
      }
    }
  }
}
