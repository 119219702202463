@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  @include min-428-break {
    gap: 14px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  @include min-428-break {
    gap: 10px;
  }
}

.backButton {
  padding: 8px 9px;
  border-radius: var(--cornerradius6) !important;
  @include singleFixedSize(34px);
  span {
    @include singleFixedSize(16px);
  }
  @include min-428-break {
    border-radius: var(--cornerradius8) !important;
    padding: 8px 12px;
    span {
      @include singleFixedSize(18px);
    }
    @include singleFixedSize(42px);
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 8px 10px;

  @include min-428-break {
    min-height: 44px;
    padding: 11px 10px;
  }

  @include min-744-break {
    width: 400px;
  }
  border-radius: var(--cornerradius10);
  background: #fff;

  p[id='list-item-title'] {
    color: var(--cst-dropitem-text-default);
    @include weight-medium;
    @include typography-s;

    @include min-744-break {
      @include typography-base;
    }
  }

  @include fixedSize(100%, 38px);
}

.item-icon {
  @include singleFixedSize(22px);
}
