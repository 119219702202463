@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.selected_chip {
  border-radius: var(--cornerradius8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Inter, sans-serif;
  padding: 0 16px;
  gap: 8px;

  @include typography-base;
  @include weight-medium;
  @include fixedSize(max-content, 38px);
  min-width: 85px;

  &.input-transport {
    box-shadow: inset 0 0 0 2px var(--cst-chips-transport-outline-default);
    background: var(--cst-chips-transport-background-default);
    color: var(--cst-chips-transport-text-default);
    svg > path {
      fill: var(--cst-chips-transport-trailingicon-default);
    }
  }
  &.input-eggs {
    box-shadow: inset 0 0 0 2px var(--cst-chips-egg-outline-default);
    background: var(--cst-chips-egg-background-default);
    color: var(--cst-chips-egg-text-default);
    svg > path {
      fill: var(--cst-chips-egg-trailingicon-default);
    }
  }
  &.input-potions {
    box-shadow: inset 0 0 0 2px var(--cst-chips-potion-outline-default);
    background: var(--cst-chips-potion-background-default);
    color: var(--cst-chips-potion-text-default);
    svg > path {
      fill: var(--cst-chips-potion-trailingicon-default);
    }
  }
  &.input-pets {
    box-shadow: inset 0 0 0 2px var(--cst-chips-cat-outline-default);
    background: var(--cst-chips-cat-background-default);
    color: var(--cst-chips-cat-text-default);
    svg > path {
      fill: var(--cst-chips-cat-trailingicon-default);
    }
  }
}

.container {
  display: flex;
  flex-direction: column;

  .drop-down-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8.5px 12px;
    border-radius: var(--cornerradius10);
    @include fixedSize(71px, 38px);
    background: var(--cst-input-searchchips-background-default);

    @include min-1440-break {
      padding: 7px 12px;
      @include fixedSize(74px, 38px);
    }

    p[id='drop-down-label'] {
      color: var(--cst-dropitem-text-default);
      @include typography-base;
      @include weight-medium;

      @include min-1440-break {
        @include typography-m;
      }
    }
  }

  .wrapper {
    position: relative;
    height: 0;

    &-opened {
      height: auto;
    }
  }

  .drop_list {
    position: absolute;
    top: 16px;
    left: -50px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    border-radius: var(--cornerradius12);
    background: var(--cst-drop-listsearch-background);
    outline: 2px solid var(--cst-drop-listsearch-outline);
    outline-offset: -2px;
    box-shadow: var(--elevation-1);

    @include min-1440-break {
      padding: 8px;
      gap: 4px;
      min-width: 174px;
    }

    &--item {
      @include fixedSize(100%, 34px);
      padding: 8px 12px;
      display: flex;
      gap: 10px;

      &:focus-within {
        box-shadow: 0 0 0 2px var(--drop);
      }

      p[id='list-item-title'] {
        color: var(--cst-dropitem-text-default);
        @include typography-s;
        @include weight-medium;

        @include min-1440-break {
          @include typography-m;
        }
      }

      @include min-1440-break {
        padding: 11px;

        @include fixedSize(100%, 46px);
      }
    }
  }
}

.icon {
  cursor: pointer;
}

.item-icon {
  @include singleFixedSize(18px);

  @include min-1440-break {
    @include singleFixedSize(22px);
  }
}
