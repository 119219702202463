@use '@button' as *;
@use '@fixed-size' as *;
@use '@tabs' as *;
@use '@queries' as *;

.header {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  box-shadow: var(--elevation-1);
  background: var(--global-white);
  min-width: 1024px;
  z-index: 3;

  @include fixedSize(100%, max-content);
  padding: 24px 20px;

  @include min-1440-break {
    @include fixedSize(100%, max-content);
    padding: 24px 40px;
  }

  .content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 18px;
    max-width: 1618px;
    @include min-1440-break {
      gap: 24px;
    }

    &__top-slot {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      gap: 12px;
      height: 47px;

      @include min-1440-break {
        gap: 32px;
        height: 50px;
      }

      &-left {
        display: flex;
      }

      &-middle {
        display: flex;
        flex: 1;
        gap: 12px;
        align-items: center;
        min-width: 604px;
        max-width: 100%;

        .category-button {
          @include singleFixedSize(44px);

          span:first-child {
            display: none;
          }

          @include min-1440-break {
            @include fixedSize(141px, 50px);
            span:first-child {
              display: block;
            }
          }
        }

        @include min-1440-break {
          gap: 18px;
          max-width: 1128px;
        }

        > *[id='header-search-div'] {
          width: 100%;
          min-width: 546px; //546

          @include min-1440-break {
            max-width: 100%; //711
          }

          @include min-1920-break {
            max-width: 967px;
          }
        }
      }

      &-right {
        display: flex;
        gap: 12px;
        justify-content: space-between;
        @include fixedSize(220px, 47px);

        @include min-1440-break {
          gap: 14px;
          @include fixedSize(253px, 50px);
        }
      }
    }

    &__bottom-slot {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &-left {
        display: flex;
        align-items: center;
        gap: 6px;
        max-width: 100%;
        padding-right: 10px;
        flex-wrap: wrap;

        .divider {
          height: 32px;
          @include min-1440-break {
            height: 46px;
          }
        }
      }

      &-right {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}
