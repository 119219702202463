@use '@fixed-size' as *;
@use '@typography' as *;
@use '@button' as *;
@use '@queries' as *;

.container {
  @include buttonSizes('42', '42', '42', '42', '46', '46');

  span.icon,
  svg {
    @include singleFixedSize(18px);

    @include min-1440-break {
      @include singleFixedSize(20px);
    }
  }
}
