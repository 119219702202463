@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.popper {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 20;
  transition: all 0.2s ease-in-out;
  background: var(--cst-dropitem-background-pressed);
  box-shadow: inset 0 0 0 2px #efefef;
  border-radius: var(--cornerradius8);

  &__opened {
    visibility: visible;
    opacity: 1;
  }
}
