@use '../../../app/queries' as *;
@use '../../../shared/ui/typography/sizes' as *;
@use '@/shared/ui/button/adaptive' as *;
@use '@fixed-size' as *;

.container {
  position: relative;
  height: 42px;
  width: 83px;
  border: 2px solid rgba(246, 246, 246, 1);
  border-radius: var(--cornerradius10);
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  padding: 10px 8px;

  @include min-428-break {
    height: 46px;
    width: 93px;
    padding: 12.5px 12px;
  }

  @include min-1024-break {
    height: 32px;
    width: 69px;
    gap: 4px;
    padding: 6px 10px;
    border-radius: var(--cornerradius6);
  }

  @include min-1440-break {
    height: 46px;
    width: 97px;
    gap: 8px;
    padding: 9px 14px;
    border-radius: var(--cornerradius10);
  }

  &-icon {
    @include singleFixedSize(18px);
    @include min-1024-break {
      @include singleFixedSize(14px);
    }
    @include min-1440-break {
      align-self: center;
      @include singleFixedSize(18px);
    }
    &-reversed {
      transform: rotate(180deg);
      transition: all 0.2s;
    }
  }

  &-title {
    letter-spacing: 1.4px;
    @include typography-s;
    color: #606060 !important;

    @include min-428-break {
      align-self: center;
      @include typography-base;
    }

    @include min-1024-break {
      @include typography-xs;
    }

    @include min-1440-break {
      gap: 8px;
      @include typography-base;
    }
  }

  &-profile_info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include min-428-break {
    gap: 10px;
  }
}

.button {
  @include buttonSizes('38', '46', '46', '42', '50', '50');
}

.drop_list {
  width: 141px;
  margin-top: 30px;
  left: -73px;
  box-shadow: var(--elevation-1);
  background-color: var(--global-white);
  border-radius: var(--cornerradius10);
  border: 2px solid #efefef;
  padding: 10px;
  @include min-1440-break {
    margin-top: 40px;
    left: -115px;
    padding: 12px;
    width: 212px;
  }
  @include min-1920-break {
    left: -66px;
  }
}

.drop-list-title {
  color: var(--global-black);
  @include typography-base;
  @include min-1440-break {
    @include typography-m;
  }
}

.drop-list-subtitle {
  color: var(--global-grey900) !important;
  @include typography-xs;
  @include min-1440-break {
    @include typography-s;
  }
}

.drop-list-text {
  color: var(--global-grey800) !important;
  @include typography-xs;
  @include min-1440-break {
    @include typography-s;
  }
}

.sheet-title {
  color: #303030;
  @include weight-semi-bold;
  @include typography-base;
  @include min-428-break {
    @include typography-l;
  }
}

.sheet-text {
  @include weight-medium;
  color: #8f8f8f;
  @include typography-s;
  @include min-428-break {
    @include typography-base;
  }
}

.icon {
  @include singleFixedSize(16px);
  @include min-428-break {
    @include singleFixedSize(20px);
  }
}
